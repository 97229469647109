@font-face {
  font-family: 'Manrope';
  src: url(./assets/fonts/Manrope-ExtraLight.ttf);
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url(./assets/fonts/Manrope-Light.ttf);
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url(./assets/fonts/Manrope-Regular.ttf);
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url(./assets/fonts/Manrope-Medium.ttf);
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url(./assets/fonts/Manrope-SemiBold.ttf);
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url(./assets/fonts/Manrope-Bold.ttf);
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url(./assets/fonts/Manrope-ExtraBold.ttf);
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --white: #ffffff;

  --red: #EE7674;

  --primary-green-1: #F0F5F4;
  --primary-green-2: #DEEDE6;
  --primary-green-3: #99C6B0;
  --primary-green-4: #99C6B0;
  --primary-green-5: #2A5C44;
  --primary-green-6: #1F483E;

  --primary-grey-1: #A7B2AE;
  --primary-grey-2: #A7B3AE;
}

body {
  margin: 0;
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
}